import { useAttractionsContext } from '@/common/app/contexts';
import { IRecommendsItem } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { checktAvailability } from '@/entities/Attractions/service/Creators';
import { useMemo } from 'react';

interface IProps {
    defaultRecommended: IRecommendsItem[];
    currentDestinationId?: string;
    currentCategoryId?: string;
    currentStateCode?: string;
    title?: string;
}

export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length;

    while (currentIndex != 0) {
        const randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array.slice(0, 4);
}

export const useRecommended = ({ defaultRecommended = [], currentCategoryId }: IProps) => {
    const { state, normalisedDate, loading } = useAttractionsContext();

    const currentRecommended = useMemo(() => {
        const recommended = normalisedDate
            ? defaultRecommended.filter(
                  (i) => state.availability[i.id] && checktAvailability(state.availability[i.id])
              )
            : defaultRecommended;
        return shuffle(
            currentCategoryId
                ? recommended.filter((i) => i.category_id === currentCategoryId)
                : recommended
        );
    }, [currentCategoryId, defaultRecommended, normalisedDate, state.availability]);

    return {
        recommended: currentRecommended,
        loading: loading.initLoading,
    };
};
