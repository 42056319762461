import { useRef } from 'react';

import * as S from './FeaturedActivities.styles';
import { SliderDots } from '@/common/ui';
import { Container } from '@/common/ui/containers/Container';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { IRecommendsItem } from './featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { FeaturedActivitiesSkeleton } from './FeaturedActivitiesSkeleton/FeaturedActivitiesSkeleton';
import { NewFeaturedActivitiesPreview } from './featuredActivitiesPreview/NewFeaturedActivitiesPreview';
import { useRecommended } from '@/common/app/hooks/useRecommended';

export type FeaturedActivitiesProps = {
    defaultActivities: IRecommendsItem[];
    title?: string;
    subTitle?: string;
    isFromSearch?: boolean;
    item_list_name: string;
    item_list_id: string;
    placeholders: {
        [key: string]: string;
    };
    withoutDots?: boolean;
    currentCategoryId?: string;
    noPadding?: boolean;
};

export const NewFeaturedActivities = ({
    defaultActivities,
    title = '',
    subTitle,
    item_list_name,
    item_list_id,
    placeholders,
    withoutDots,
    currentCategoryId,
    noPadding,
}: FeaturedActivitiesProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const { loading, recommended } = useRecommended({
        defaultRecommended: defaultActivities,
        currentCategoryId,
    });

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
        dotActive,
    } = useCarousel({
        ref: scrollRef,
        countItems: recommended.length || 4,
    });

    const skeletonObj = new Array(4).fill({ id: '' });
    if (!loading && !recommended.length) {
        return <></>;
    }

    return (
        <S.Wrapper noPadding={noPadding}>
            <Container>
                <S.Block data-test-id="Widget-attraction" className="container">
                    {Boolean(title) && (
                        <S.Header>
                            <S.LeftPartHeader>
                                <S.Title>{title}</S.Title>
                                {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
                            </S.LeftPartHeader>
                        </S.Header>
                    )}
                    <S.CarouselWraper>
                        <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                            <S.InnerContainer className="carousel-container">
                                {loading ? (
                                    <>
                                        {skeletonObj.map((slide, index) => (
                                            <FeaturedActivitiesSkeleton key={slide.id || index} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {recommended.map((slide, index) => (
                                            <NewFeaturedActivitiesPreview
                                                {...slide}
                                                numberOfItems={recommended.length}
                                                position={index}
                                                key={slide.id}
                                                listName={title}
                                                item_list_id={item_list_id}
                                                item_list_name={item_list_name}
                                                promotion_name={title}
                                                placeholder={
                                                    placeholders?.[slide.id] ||
                                                    slide.placeholder ||
                                                    ''
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </S.InnerContainer>
                        </S.Gallery>
                        {prevBtnIsActive && withDot && (
                            <S.CenterLeftArrow
                                onClick={() => onClickHandler(DIRECTION.prev)}
                                disabled={false}
                            />
                        )}
                        {nextBtnIsActive && withDot && (
                            <S.CenterRightArrow
                                onClick={() => onClickHandler(DIRECTION.next)}
                                disabled={false}
                            />
                        )}
                    </S.CarouselWraper>
                    {!!withDot && !withoutDots && (
                        <S.DotsSlider>
                            {[...Array(recommended.length).keys()].map((el) => {
                                return (
                                    <SliderDots isActive={el === Math.ceil(dotActive)} key={el} />
                                );
                            })}
                        </S.DotsSlider>
                    )}
                </S.Block>
            </Container>
        </S.Wrapper>
    );
};
